import * as pathToRegexp from 'path-to-regexp';

function compilePath(path: string) {
  const generator = pathToRegexp.compile(path);

  return generator;
}

export function generatePath(
  path = '/',
  params?: { [paramName: string]: string | number | boolean | undefined },
): string {
  return path === '/' ? path : compilePath(path)(params);
}
